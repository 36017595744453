<template>
  <div :class="{'in-progress': progressActive}"
       class="feedback-section is-progress-bar">
    <div v-if="title"
         class="feedback-section__title-wrapper">
      <span v-if="icon"
            :class="[`feedback-section__icon--${icon}`]"
            class="feedback-section__icon"></span>
      <h3 class="feedback-section__title"
          v-html="title"></h3>
      <slot name="header"></slot>
    </div>
    <div :class="{'feedback-section__content--with-icon': icon}"
         class="feedback-section__content">
      <slot></slot>
      <div class="feedback-section__footer">
        <button v-if="!hideButton"
                class="sk-btn sk-btn--default feedback-section__btn"
                :class="{'is-disabled': disableButton}"
                @click="$emit('save')">{{ buttonText || $gettext('Save') }}</button>
        <div>{{ buttonDescription }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      buttonDescription: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: ''
      },
      hideButton: {
        type: Boolean
      },
      disableButton: {
        type: Boolean,
        default: false
      },
      progressActive: Boolean
    }
  };
</script>

<style scoped>
  .feedback-section {
    padding: 20px 30px;
    background-color: #fff;
  }

  .feedback-section__title-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .feedback-section__icon {
    min-width: 40px;
    min-height: 30px;
    background-position: left top;
    background-repeat: no-repeat;
  }

  .feedback-section__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    color: #9b97b1;
  }

  .feedback-section__btn {
    width: auto;
    min-width: 66px;
    margin-left: 5px;
    padding: 0 5px;
  }

  .feedback-section__icon--assignment {
    background-image: url(~@assets/imgs/feedback_icons/assignment_section_icon.svg);
  }

  .feedback-section__icon--personal-feedback {
    background-image: url(~@assets/imgs/feedback_icons/personal_feedback_section_icon.svg);
  }

  .feedback-section__icon--personal-feedback-interpreter-side {
    background-image: url(~@assets/imgs/feedback_icons/personal_feedback_section_icon_interpreter_side.svg);
  }

  .feedback-section__icon--duration {
    background-image: url(~@assets/imgs/feedback_icons/duration_section_icon.svg);
  }

  .feedback-section__icon--invoice {
    background-image: url(~@assets/imgs/feedback_icons/invoice_section_icon.svg);
  }

  .feedback-section__icon--travel {
    background-image: url(~@assets/imgs/feedback_icons/travel_section_icon.svg);
  }

  .feedback-section__icon--complete {
    background-image: url(~@assets/imgs/feedback_icons/check_circled_icon.svg);
  }

  .feedback-section__icon--problems {
    background-image: url(~@assets/imgs/feedback_icons/bell_icon.svg);
  }

  .feedback-section__content--with-icon {
    padding-left: 40px;
  }

  .feedback-section__title {
    display: flex;
    width: 100%;
    font-size: 18px;
  }

  @media (max-width: 767px) {
    .feedback-section {
      padding: 20px 15px;
    }
  }
</style>
